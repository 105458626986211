import React from 'react'

import { capitalize } from '@cms/utils'
import { ShowMore } from '../../shared/ShowMore'

import * as S from './styled'

const UnitCard = ({image, alt, name, address, slug, label, hasWhiteBg, isWhiteColor}) =>  (
  <S.UnitCard hasWhiteBg={hasWhiteBg}>
    <S.UnitImage fluid={image} alt={alt} />
    <S.UnitInfo hasWhiteBg={hasWhiteBg}>
      <S.UnitTitle hasWhiteBg={hasWhiteBg}>{capitalize(name)}</S.UnitTitle>
      <S.UnitAdress hasWhiteBg={hasWhiteBg}>{capitalize(address)}</S.UnitAdress>
      <ShowMore url={slug} label={label} isWhiteColor={isWhiteColor} />
    </S.UnitInfo>
  </S.UnitCard>
)

export default UnitCard
