import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useTranslations } from 'gatsby-plugin-translate'
import { isMobile } from '@cms/utils'

import Carousel from '@brainhubeu/react-carousel'
import '@brainhubeu/react-carousel/lib/style.css'
import { ArrowLeft, ArrowRight } from '../../shared/CarouselArrow'
import { UnitCard } from '../UnitCard'

const UnitsCarousel = ({ unitsWpData, unitsDpData, stateCheckedValue }) => {
  const data = useStaticQuery(graphql`
    query {
      defaultImage: file(relativePath: { eq: "default.jpg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const t = useTranslations()
  const translatedShowMore = t`Ver mais`

  const unitImageDefault = {
    imgFluid: [data.defaultImage.childImageSharp.fluid],
    imgAlt: 'unidade Alta',
  }

  const [mobileDevice, setMobileDevice] = useState(false)

  const screen = typeof window !== `undefined` ? window.screen : false

  useEffect(() => {
    if (isMobile(screen)) {
      setMobileDevice(true)
    }
  }, [screen])

  const unitsListFilteredPerState = unitsDpData.filter(unit => unit.state === stateCheckedValue)

  const unitsImageList = unitsWpData.map(({ node: { acf: item } }) => item)

  return (
    <Carousel
      addArrowClickHandler
      arrowRightDisabled={<></>}
      arrowRight={<ArrowRight />}
      arrowLeftDisabled={<></>}
      arrowLeft={<ArrowLeft />}
      slidesPerPage={mobileDevice ? 1 : 4}
      itemWidth={mobileDevice ? 264 : 336}
      offset={24}
    >
      {unitsListFilteredPerState.map(unit => {
        const matchUnitImages = unitsImageList.filter(
          unitImage => unitImage.unit_id === unit.id.toString()
        )
        const matchedUnitImages = matchUnitImages?.map(
          img => img?.unit_image?.localFile?.childImageSharp?.fluid
        )

        const matchedUnitImage = {
          imgFluid: matchedUnitImages,
          imgAlt: matchUnitImages[0]?.unit_image?.alt_text || unitImageDefault.imgAlt,
        }

        const unitImage = [unitImageDefault, matchedUnitImage][Number(matchedUnitImages.length > 0)]

        return (
          <UnitCard
            key={unit.id}
            image={unitImage.imgFluid}
            alt={unitImage.imgAlt}
            name={unit.name}
            address={unit.simpleAddress}
            slug={unit.slug}
            label={translatedShowMore}
            isWhiteColor
          />
        )
      })}
    </Carousel>
  )
}

export default UnitsCarousel
