import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useTranslations } from 'gatsby-plugin-translate'

import Carousel from '@brainhubeu/react-carousel'
import '@brainhubeu/react-carousel/lib/style.css'

import { isMobile, slugfy } from '@cms/utils'

import { ArrowLeft, ArrowRight } from '../../../shared/CarouselArrow'
import { ShowMore } from '../../../shared/ShowMore'

import * as S from './styled'

const ClinicalStaffCarousel = ({ clinicalStaffData, stateCheckedValue }) => {
  const t = useTranslations()
  const translatedShowMore = t`Ver mais`

  const filterStaff = (staff, state) =>
    staff.filter(({ node: { acf: item } }) => [state, 'Ambos'].includes(item.acting_region))

  const staffPerRegion = filterStaff(clinicalStaffData, stateCheckedValue)

  const [mobileDevice, setMobileDevice] = useState(false)

  const screen = typeof window !== `undefined` ? window.screen : false

  useEffect(() => {
    if (isMobile(screen)) {
      setMobileDevice(true)
    }
  }, [screen])

  return (
    <Carousel
      addArrowClickHandler
      arrowRightDisabled={<></>}
      arrowRight={<ArrowRight />}
      arrowLeftDisabled={<></>}
      arrowLeft={<ArrowLeft />}
      slidesPerPage={4}
      itemWidth={mobileDevice ? 192 : 276}
      offset={24}
    >
      {staffPerRegion.map(({ node: { title, slug, acf: item } }) => (
        <S.ProfessionalCard key={title}>
          <S.ProfessionalPhoto
            fluid={item.in_spotlight.picture.localFile.childImageSharp.fluid}
            alt={item.in_spotlight.picture.alt_text}
          />
          <S.ProfessionalName>
            {item.title_gender} {title}
          </S.ProfessionalName>
          <S.ProfessionalCouncilNumber>{item.council_number}</S.ProfessionalCouncilNumber>
        </S.ProfessionalCard>
      ))}
    </Carousel>
  )
}

ClinicalStaffCarousel.propTypes = {
  clinicalStaffData: PropTypes.array.isRequired,
  stateCheckedValue: PropTypes.string.isRequired,
}

export default ClinicalStaffCarousel
