import styled from 'styled-components'
import media from 'styled-media-query'
import { Container } from '../shared/container'
import BackgroundImage from 'gatsby-background-image'

export const BannerLink = styled.a`
  text-decoration: none;
`

export const BannerImage = styled(BackgroundImage)`
  z-index: -100;
  width: 100%;
  height: calc(100vh - ${props => props.theme.headerHeight.desktop});

  ${media.lessThan('medium')`
    height: calc(100vh - ${props => props.theme.headerHeight.mobile});
  `}
`

export const BannerMainContainer = styled(Container)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const BannerTextContainer = styled.div`
  font-family: 'Dasa Sans';
  max-width: 64.3rem;
  color: ${props => props.theme.colors.white};

  ${media.lessThan('small')`
    max-width: 28.6rem;
  `}
`

export const SmallText = styled.p`
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 3.2rem;
  margin: 0 0 0.8rem 0;

  ${media.lessThan('small')`
    font-size: 1.6rem;
    line-height: 2.4rem;
  `}

  @media only screen and (max-width: 320px) {
    font-size: 1.2rem;
    line-height: 2rem;
  }
`

export const HighlightText = styled.p`
  font-size: 7.2rem;
  line-height: 8rem;
  font-weight: normal;
  margin: 0;
  color: ${props => props.theme.colors.white};

  ${media.between('small', 'medium')`
    font-size: 5.8rem;
    line-height: 6.6rem;
  `}

  ${media.lessThan('small')`
    width: 100%;
    font-size: 3.2rem;
    line-height: 4rem;
    text-align: left;
  `}

  @media only screen and (max-width: 320px) {
    font-size: 3rem;
    line-height: 3.8rem;
  }
`
